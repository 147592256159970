import React from 'react';
import { Link } from 'react-router-dom';

import AddProduct from '../../common/products/addProduct';
import no_img from '../../../assets/no_image.jpg';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import {
  formatNumber,
  priceDiscount, priceDiscountValue,
} from '../../../middleware/common-functions';
import { Sharebutton } from '../../common/buttons';

const Images = ({ alt, images, videos, handleImage, active_image }) => {
  if (!images || images.length < 1) {
    return (
      <div className="images">
        <img src={no_img} alt={alt} className="img-fluid" />
      </div>
    );
  }
  const slides = images.map((image_url, key) => (
    <img
      src={image_url}
      alt={alt}
      className="img-fluid"
      key={`image_url_${key}`}
    />
  ));
  const thumbnails = [...slides];
  if (videos && videos.length > 0) {
    const videoSlides = videos.map((video_id) => (
      <iframe
        width="500"
        height="281"
        src={`https://www.youtube.com/embed/${video_id}`}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    ));
    const videoThumbnails = videos.map((video_id, key) => (
      <img
        src={`https://img.youtube.com/vi/${video_id}/mqdefault.jpg`}
        alt={alt}
        className="img-fluid"
        key={`image_url_${key}`}
      />
    ));
    slides.push(...videoSlides);
    thumbnails.push(...videoThumbnails);
  }
  return (
    <div className="images">
      <Carousel value={active_image} slides={slides} onChange={handleImage} />
      {images.length > 1 && (
        <Dots
          number={slides.length}
          thumbnails={thumbnails}
          value={active_image}
          onChange={handleImage}
        />
      )}
    </div>
  );
};

const Pricing = ({ prices, unit }) => {
  const discount = prices.regular
    ? priceDiscountValue(prices.regular, prices.main)
    : false;
  return (
    <div className="pricing">
      <h2>
        {prices.regular && (
          <div>
            <span className="line">${prices.regular}</span>{' '}
            <span> ({discount} de descuento)</span>
          </div>
        )}
        {prices.main > 0 ? `$${formatNumber(prices.main)}` : '-'}
        <span className="unit">/{unit}</span>
      </h2>
    </div>
  );
};
const Desc = ({ product }) => {
  const location = JSON.parse(localStorage.getItem('location') || '{}');
  return (
    <div className="desc">
      <p>
        <Link to={`/marca/${product.aliasd}`}>{product.aliasd}</Link>
      </p>
      <h1>{product.descp1}</h1>
      <Raiting rating={product.rating} />
      {product.description && (
        <p className="long-desc">{product.description}</p>
      )}
      {location.id === '0' && product.shipping !== 'Nacional' ? (
        <p className="text-danger">
          Este producto no se puede enviar a tu locación
        </p>
      ) : null}
    </div>
  );
};

const Raiting = ({ rating }) => {
  const rating_value = rating ? rating.value : 0; ///1 to 5
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    stars.push(
      <i
        className={`${i <= rating_value ? 'fas' : 'far'} fa-star`}
        key={`stars_${i}`}
      ></i>
    );
  }
  if (!rating) return null;
  return (
    <div className="rating d-flex small e">
      <div className="text-warning mr-2">{stars}</div>
      <span className="text-muted">
        ({rating.cant > 1 ? `${rating.cant} valoraciones` : '1 valoración'})
      </span>
    </div>
  );
};
const ProductSection = ({
  product,
  promptAdd,
  amount,
  current_amount,
  handleAmount,
  handleImage,
  active_image,
  onUpdate,
}) => (
  <div className="row product">
    <div className="col-md-6 position-relative">
      {product.icon ? (
        <img src={product.icon} className="discount_img" alt="discount_img" />
      ) : null}
      <Images
        idpart={product.idpart}
        handleImage={handleImage}
        active_image={active_image}
        alt={product.descp1}
        images={product.images}
      />
    </div>
    <div className="col-md-6 right">
      <Desc product={product} />
      <Sharebutton
        text="Conoce nuestro producto"
        url={window.location.href}
        title={product.descp1}
      />
      <Pricing
        prices={{
          main: product.price_num,
          regular: product.price_regular,
        }}
        unit={product.xium ? product.xium.descrp : 'pz'}
      />
      <AddProduct
        promptAdd={promptAdd}
        surface={
          product.xiuds && product.xiuds.length > 0 ? product.xiuds : false
        }
        block
        price={product.price}
        handleAmount={handleAmount}
        amount={amount}
        badge
        current_amount={current_amount}
        onUpdate={onUpdate}
      />
      {!product.stock && product.price > 0 ? 'Puede tardar en llegar' : null}
    </div>
  </div>
);

export default ProductSection;
