import React, { useState } from 'react';
import { Link } from 'react-router-dom';
//@ts-ignore next line
import Carousel from '@brainhubeu/react-carousel';

import no_img from '../../../assets/no_image.jpg';
import AddProduct from './addProduct';
import {
  formatNumber,
  getProductUrl,
  priceDiscountValue,
} from '../../../middleware/common-functions';
import { CartProductItem } from '../../../models/cart_model';
import { ProductItem, FromType } from '../../../models/product_model';

type InCartProductDescProps = {
  product: CartProductItem;
  price?: boolean;
  changeFun?: any;
  small?: boolean;
  review?: boolean;
};

type InCartBlockProps = {
  product: CartProductItem;
  handleDelete: any;
  changeFun: any;
};

type CantInputProps = {
  cant: number | string;
  changeFun: any;
  product_id?: string;
  refTag?: any;
  from?: FromType;
};

type CartBlockProps = {
  product: CartProductItem;
};

type DescProps = { classes?: string; data: ProductItem; unit?: string };

type DetailsProps = { items: DetailsItem[]; price: number; product_id: string };
type DetailsItem = {
  value: string;
  name: string;
};

type ProductBlockProps = {
  product: ProductItem;
};

type ListBlockProps = {
  product: ProductItem;
  addProductCart?: any;
  from?: FromType;
};

type ArrowsProps = { slideImg: any; active_image: number; imagesNav: number };

export const CartBlock: React.FC<CartBlockProps> = ({ product }) => {
  const unit = product.unit ? product.unit : 'pz';
  return (
    <div className="product_cart row mb-2">
      <div className="col-md-4 text-center">
        <img
          src={product.image || no_img}
          alt={product.title}
          className="img-fluid"
        />
      </div>
      <div className="col-md-8">
        <h5>{product.title}</h5>
        <small>
          Cantidad: {product.cant} /{unit}
        </small>
        <p className="price">${product.price && product.price}</p>
      </div>
    </div>
  );
};

export const ProductBlock: React.FC<ProductBlockProps> = ({ product }) => (
  <div className="product_block">
    <div className="row m-0">
      <div className="col-md-4 p-0 text-center">
        <Link to={getProductUrl(product)}>
          <img
            src={
              product.images && product.images.length > 0
                ? product.images[0]
                : no_img
            }
            className="img-fluid"
            alt={product.descp1}
          />
        </Link>
      </div>
      <Desc
        classes="col-md-8 px-2"
        data={product}
        unit={product.xium ? product.xium.descrp : 'pz'}
      />
    </div>
  </div>
);

export const ListBlock: React.FC<ListBlockProps> = ({
  product,
  addProductCart,
  from,
}) => {
  const [active_image, setActive_image] = useState(0);
  const imagesNav = product.images.length;

  const handleImage = (ev: any) => {
    setActive_image(ev);
  };
  const slides =
    !product.images || product.images.length < 1
      ? [
          <div className="images">
            <Link to={getProductUrl(product)}>
              <img src={no_img} alt={product.descp1} className="img-fluid" />
            </Link>
          </div>,
        ]
      : product.images.map((image_url, key) => (
          <Link to={getProductUrl(product)} key={`image_url_${key}`}>
            <img src={image_url} alt={product.descp1} className="img-fluid" />
          </Link>
        ));

  const slideImg = (ev: { currentTarget: HTMLElement }) => {
    const side = ev.currentTarget.dataset.side;
    const current_active = active_image;
    if (side === 'right') {
      setActive_image(current_active + 1);
    } else {
      setActive_image(current_active - 1);
    }
  };
  if (!product.price || product.price === '0.00') {
    return null;
  }
  return (
    <div className="product_block_list px-2">
      <div>
        {product.icon ? (
          <img src={product.icon} className="discount_img" alt="discount_img" />
        ) : null}
        <div className="text-center images_cont">
          {imagesNav > 1 && (
            <Arrows
              slideImg={slideImg}
              active_image={active_image}
              imagesNav={imagesNav}
            />
          )}
          <Carousel
            value={active_image}
            slides={slides}
            onChange={handleImage}
          />
        </div>
        <Desc
          classes=""
          data={product}
          unit={product.xium ? product.xium.descrp : 'pz'}
        />
      </div>

      {product.price && addProductCart ? (
        <AddProduct
          cant_hidd
          block
          product_id={product.id}
          from={from}
          promptAdd={addProductCart}
          price={product.price}
          surface={
            product.xiuds && product.xiuds.length > 0
              ? product.xiuds
              : undefined
          }
        />
      ) : null}
    </div>
  );
};

export const AltoValorBlock: React.FC<ListBlockProps> = ({
  product,
  addProductCart,
  from,
}) => {
  const [active_image, setActive_image] = useState(0);
  const imagesNav = product.images.length;

  const handleImage = (ev: any) => {
    setActive_image(ev);
  };
  const slides =
    !product.images || product.images.length < 1
      ? [
          <div className="images">
            <Link to={getProductUrl(product)}>
              <img src={no_img} alt={product.descp1} className="img-fluid" />
            </Link>
          </div>,
        ]
      : product.images.map((image_url, key) => (
          <Link to={getProductUrl(product)} key={`image_url_${key}`}>
            <img src={image_url} alt={product.descp1} className="img-fluid" />
          </Link>
        ));

  const slideImg = (ev: { currentTarget: HTMLElement }) => {
    const side = ev.currentTarget.dataset.side;
    const current_active = active_image;
    if (side === 'right') {
      setActive_image(current_active + 1);
    } else {
      setActive_image(current_active - 1);
    }
  };
  if (!product.price || product.price === '0.00') {
    return null;
  }
  return (
    <div className="product_block_alto_valor px-2">
      <div>
        {product.icon ? (
          <img src={product.icon} className="discount_img" alt="discount_img" />
        ) : null}
        <div className="text-center images_cont">
          {imagesNav > 1 && (
            <Arrows
              slideImg={slideImg}
              active_image={active_image}
              imagesNav={imagesNav}
            />
          )}
          <Carousel
            value={active_image}
            slides={slides}
            onChange={handleImage}
          />
        </div>
        <Desc
          classes=""
          data={product}
          unit={product.xium ? product.xium.descrp : 'pz'}
        />
      </div>

      {product.price && (
        <AddProduct
          cant_hidd
          block
          product_id={product.id}
          from={from}
          promptAdd={addProductCart}
          price={product.price}
          surface={
            product.xiuds && product.xiuds.length > 0
              ? product.xiuds
              : undefined
          }
        />
      )}
    </div>
  );
};

const Arrows: React.FC<ArrowsProps> = ({
  slideImg,
  active_image,
  imagesNav,
}) => (
  <div className="nav_arrows">
    <button
      className="btn "
      onClick={slideImg}
      disabled={active_image === 0}
      data-side="left"
    >
      <i className="fas fa-chevron-left"></i>
    </button>
    <button
      className="btn "
      onClick={slideImg}
      disabled={active_image === imagesNav - 1}
      data-side="right"
    >
      <i className="fas fa-chevron-right"></i>
    </button>
  </div>
);

export const InCartBlock: React.FC<InCartBlockProps> = ({
  product,
  handleDelete,
  changeFun,
}) => (
  <div className="product_cart my-2 cart_row">
    <div className="products">
      <InCartProductDesc product={product} changeFun={changeFun} />
    </div>
    <div className="price xs_hidden  text-center cart_price">
      $
      {product.regluar_price
        ? formatNumber(product.regluar_price)
        : formatNumber(product.price)}
    </div>
    <div className="price xs_hidden  text-center cart_price">
      {product.regluar_price
        ? `$${formatNumber(
            product.regluar_price * product.cant - product.price * product.cant
          )}`
        : '-'}
    </div>
    <div className="quanty xs_hidden  text-center">
      <CantInput
        cant={product.cant}
        changeFun={changeFun}
        product_id={product.id}
        from="cart"
      />
    </div>
    <div className="price xs_hidden text-center cart_price">
      ${formatNumber((Number(product.price) * product.cant).toFixed(2))}
    </div>
    <div className="del">
      <button
        className="btn btn-outline-danger"
        onClick={handleDelete}
        data-product_id={product.id}
      >
        <i className="fas fa-trash"></i>
      </button>
    </div>
  </div>
);

export const InCartProductDesc: React.FC<InCartProductDescProps> = ({
  product,
  price,
  changeFun,
  small,
  review,
}) => (
  <div className="row py-3">
    <div
      className={`${
        small ? 'col-2' : 'col-4'
      } d-flex align-content-center justify-content-center`}
    >
      <Link to={getProductUrl(product)}>
        <img
          src={product.image || no_img}
          alt={product.title}
          className="img-fluid"
        />
      </Link>
    </div>
    <div className="col-8 ">
      <Link to={getProductUrl(product)}>{product.title}</Link>
      {product.details && (
        <Details
          items={product.details}
          price={product.price}
          product_id={product.id}
        />
      )}
      {price && (
        <div className="cart_price">${formatNumber(product.price)}</div>
      )}
      {review && (
        <Link
          to={`/valorar/${product.id}`}
          className="btn text-capitalize mt-3 pl-0"
        >
          Valorar Producto <i className="far fa-star"></i>
        </Link>
      )}
      {!review && (
        <div className="row d-md-none mt-2">
          <div className="col-12 text-center cart_price">
            ${formatNumber(Number(product.price) * product.cant)}
          </div>
          <div className="col-12  text-center">
            <CantInput
              cant={product.cant}
              changeFun={changeFun}
              product_id={product.id}
              from="cart"
            />
          </div>
        </div>
      )}
    </div>
  </div>
);

const Desc: React.FC<DescProps> = ({ classes, data, unit }) => {
  const discount = data.price_regular
    ? priceDiscountValue(
        data.price_regular.replace(',', ''),
        data.price.replace(',', '')
      )
    : false;

  return (
    <div className={`${classes} desc`}>
      <div>
        <Link to={getProductUrl(data)}>
          <div className="font-weight-bold text-uppercase text-dark">
            {data.descp1}
          </div>
        </Link>
        <span className={`brand ${!data.aliasd && 'opacity_0'}`}>
          {data.aliasd || 'Sin Marca'}
        </span>
      </div>
      <div className="price">
        <span>{parseInt(data.price) > 0 ? `$${data.price}` : '-'}</span>
        <span className="unit">/{unit}</span>
        {data.price_regular && (
          <React.Fragment>
            <span className="regluar_price">
              <span>${data.price_regular}</span>{' '}
              <span>({discount} de descuento)</span>
            </span>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

const Details = ({ items, price, product_id }: DetailsProps) => {
  const elements = items.map((item, key) => (
    <span
      className="details"
      id={`details_${key}`}
      key={`details_${product_id}_${key}`}
    >
      {item.name}: <b>{item.value}</b>
    </span>
  ));
  if (price)
    elements.push(
      <span className="details d-md-none" key={`details_${product_id}_price`}>
        Precio: <b>${price}</b>
      </span>
    );
  return <React.Fragment>{elements}</React.Fragment>;
};

export const CantInput: React.FC<CantInputProps> = ({
  cant,
  changeFun,
  product_id,
  refTag,
  from,
}) => {
  const cant_num = cant ? cant : '';
  return (
    <div className="input_btns">
      {' '}
      <input
        className="form-control"
        value={cant_num}
        id={`cant_input_${from}_${product_id}`}
        data-type="input"
        ref={refTag}
        onChange={changeFun}
        data-product={product_id}
      />
      <div className="btn_toggles">
        <button
          onClick={changeFun}
          data-type="btn_minus"
          data-product={`${from}_${product_id}`}
          className={`btn ${cant_num === 1 && 'disabled'}`}
        >
          <i className="fas fa-minus-circle"></i>
        </button>
        <button
          onClick={changeFun}
          data-type="btn_plus"
          data-product={`${from}_${product_id}`}
          className="btn"
        >
          <i className="fas fa-plus-circle"></i>
        </button>
      </div>
    </div>
  );
};
