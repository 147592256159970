import phone from 'phone';

import mathasa from '../assets/logo_mathasa_croped.png';
import decasa from '../assets/logo_decasa.png';
import idecasa from '../assets/logo_idecasa.png';
import designM from '../assets/designmathasa.png';

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function compare(a, b) {
  const value_A = a.name.toUpperCase();
  const value_B = b.name.toUpperCase();

  let comparison = 0;
  if (value_A > value_B) {
    comparison = 1;
  } else if (value_A < value_B) {
    comparison = -1;
  }
  return comparison;
}

export function generateRange(min, max, step) {
  let arr = [];
  for (let i = min; i <= max; i += step) {
    arr.push(i);
  }

  return arr;
}

export function slugify(string) {
  ///found on https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return (
    string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      // eslint-disable-next-line
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      // eslint-disable-next-line
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      // eslint-disable-next-line
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '')
  ); // Trim - from end of text
}

export function formatNumber(num, round = true, decimals = 2) {
  if (round) {
    num = parseFloat(num);
    num = num.toFixed(decimals);
  }
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function toNumber(num) {
  return parseFloat(num.split(',').join());
}

export function shippingTxt(shipping) {
  switch (shipping) {
    case 'shipping':
      return 'Envío a Domicilio';
    default:
      return 'Recoger en Sucursal';
  }
}

export function statusTxt(status) {
  switch (status) {
    case 'shipped':
      return 'Envíado';

    default:
      return 'Pendiente';
  }
}

export function getNeededAmount(amunt_needed, product_amount) {
  if (!product_amount || !amunt_needed || amunt_needed === 0) {
    ///when it's zero always return one,
    //customer only need one per instalation
    return 1;
  }
  let needed_amount = (amunt_needed * product_amount).toFixed(2);
  const decimals = (needed_amount + '').split('.').pop();
  if (decimals >= 20) {
    ///equal or grater than .20
    needed_amount = Math.ceil(needed_amount);
  } else {
    needed_amount = Math.floor(needed_amount);
  }
  return Number(needed_amount) || 1;
}

export function getRandomNumber(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function updateCartCants() {
  const cart_items = JSON.parse(localStorage.getItem('cart'));
  const cart_elem = document.getElementById('cart_items_cant');
  const cart_elem_mob = document.getElementById('cart_items_cant_mob');
  if (!cart_elem) {
    return;
  }
  if (!cart_items) {
    cart_elem.textContent = 0;
    cart_elem_mob.textContent = 0;
    return;
  }
  const cant = cart_items.length;
  cart_elem.textContent = cant;
  cart_elem_mob.textContent = cant;
}

export function getLogo() {
  const page = window.location.host;

  switch (page) {
    case 'mathasa.mx':
      return mathasa;
    case 'decasajuarez.com':
    case 'decasahermosillo.com':
    case 'decasa.mx':
      return decasa;
    case 'idecasa.mx':
      return idecasa;
    case 'designmathasa.mx':
      return designM;

    default:
      return mathasa;
  }
}

export function isDesign() {
  const page = window.location.host;
  return page === 'designmathasa.mx'; //|| page === 'localhost:3000';
}

export function priceDiscount(regular, discounted) {
  return parseFloat(
    100 - (parseFloat(discounted) * 100) / parseFloat(regular.replace(',', '')),
    1
  ).toFixed(1);
}

export function priceDiscountValue(regular, discounted) {
let discount = parseFloat(regular.replace(',', '')) - parseFloat(discounted);
  return "$" + discount.toFixed(2);
}

export function aMeses(total, meses) {
  const new_total = total + total * meses.comission;
  const monthly_pay = new_total / meses.meses;

  return {
    monthly_pay: formatNumber(monthly_pay),
    total_pay: formatNumber(new_total),
  };
}

export function phoneFormat(phone_num, phone_code = 'mx', digits = 15) {
  let phone_formated = phone(phone_num, phone_code)[0];
  phone_formated = phone_formated.replace(/\D/g, '');
  const diff_digits = digits - phone_formated.length;
  if (diff_digits === 0) return phone_formated;
  ///add leading zeros
  for (let i = 0; i < diff_digits; i++) {
    phone_formated = '0' + phone_formated;
  }
  return phone_formated;
}

export function renderDeliveryDates(shipping) {
  if (!shipping) return '-';
  let response = [];
  let package_num = 1;
  if (shipping.in_stock && shipping.in_stock.delivery_date) {
    response.push(`Paquete #${package_num} ${shipping.in_stock.delivery_date}`);
    package_num++;
  }
  if (shipping.no_stock && shipping.no_stock.length > 0) {
    shipping.no_stock.forEach((shipping_data) => {
      response.push(`Paquete #${package_num} ${shipping_data.delivery_date}`);
      package_num++;
    });
  }
  return response.join(' | ');
}

export function setOrderID(length = 10) {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function setOrderNumber() {
  return Math.random().toString(36).substring(2, 15) + 5;
}

export function getProductUrl(product) {
  if ((product.grpsbi && product.grpsbi.length === 0) || !product.descp1) {
    return `/product/${product.id}`;
  }
  return `/lista/${slugify(product.grpsbi[0])}/${slugify(
    product.grpsbi[1]
  )}/${slugify(product.grpsbi[2])}/${slugify(product.descp1)}/${product.id}`;
}
