import firebase, { db } from './firebase';
import { updateCartCants, formatNumber } from './common-functions';
import axios from './axios';
import { CartItem, CartProductItem, PromotionItem } from '../models/cart_model';
import { ProductXiuds, ProductItem } from '../models/product_model';
import { UserType } from '../models/user_model';

type getCartFun = (full_cart?: boolean) => Promise<{
  cart_db?: CartItem | false;
  cart_products: CartProductItem[];
  promotion: PromotionItem | false;
}>;

type addProductCartFun = (props: {
  product_amount: number;
  product_id: string;
  product_data: ProductItem;
}) => Promise<boolean | void>;

function getSurface(data: ProductXiuds[], amount: number): number | false {
  // amount = parseInt(amount);
  ////data comes from product.xiuds
  const surface = data.find((i) => {
    return i.idunmd === 'mt2';
  });
  ///run only on mt2 values
  if (!surface) {
    return false;
  }
  return formatNumber(surface.fconvs * amount, true);
}

export const addProductCart: addProductCartFun = async ({
  product_amount,
  product_id,
  product_data,
}) => {
  let user = JSON.parse(localStorage.getItem('user') || '{}');
  if (!user.id) {
    user = await createAnnonymousUser();
  }
  const cart_products: CartProductItem[] = localStorage.getItem('cart')
    ? JSON.parse(localStorage.getItem('cart') || '[]')
    : [];
  const unit = product_data.xium ? product_data.xium.descrp : 'pz';
  const in_cart_amount = cart_products
    ? cart_products.find((item) => item.id === product_id)
    : false;

  if (in_cart_amount) {
    product_amount += in_cart_amount.cant;
  }
  if (isNaN(product_amount) || product_amount <= 0) {
    product_amount = 1;
  }

  const product_details = [
    {
      name: 'Unidad',
      value: unit,
    },
    {
      name: 'Tipo',
      value: product_data.grpsbi[2],
    },
  ];
  if (product_data.xiuds && product_data.xiuds.length > 0) {
    const surface = getSurface(product_data.xiuds, product_amount);
    if (surface) {
      product_details.unshift({
        name: 'Estas cajas cubren ',
        value: `${formatNumber(surface)} m2`,
      });
      product_details.unshift({
        name: 'Precio por m2',
        value: `$${formatNumber(
          product_data.price_num / product_data.xiuds[0].fconvs
        )}`,
      });
    }
  }

  const data: CartProductItem = {
    cant: product_amount,
    id: product_id,
    idpart: product_data.idpart,
    grpsbi: product_data.grpsbi,
    image:
      product_data.images && product_data.images.length > 0
        ? product_data.images[0]
        : false,
    title: product_data.descp1,
    price: product_data.price_num,
    details: [...product_details],
    unit,
    aliasd: product_data.aliasd,
  };
  ///send vesta event
  if (typeof window.vCustomer != 'undefined') {
    window.vCustomer.startEvent(window.vCustomer.ADD_TO_CART);
  }

  const collection = db.collection('cart').doc(user.id);

  // Save timestamp and user info
  if (user.email && user.displayName) {
    await collection.set({
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      email_sent: false,
      user_email: user.email,
      user_display_name: user.displayName,
      has_email: true,
    });
  } else {
    await collection.set({
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      email_sent: false,
      has_email: false,
    });
  }

  return collection
    .collection('products')
    .doc(product_id)
    .set(data, { merge: true })
    .then(() => {
      cart_products.push(data);
      localStorage.setItem('cart', JSON.stringify(cart_products));
      setTimeout(() => {
        updateCartCants();
      }, 500);
      ///send vesta event
      if (typeof window.vCustomer != 'undefined') {
        window.vCustomer.finishEvent(window.vCustomer.ADD_TO_CART);
      }
      return true;
    })
    .catch((error) => {
      console.error('Error writing document: ', error);
    });
};

const createAnnonymousUser = async () => {
  ///create annonymus user
  try {
    const data = await firebase.auth().signInAnonymously();
    const user: UserType = {
      isAnonymous: true,
      id: data.user!.uid,
    };
    localStorage.setItem('user', JSON.stringify(user));
    return user;
  } catch (error: any) {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    console.log('auth-context.js:30 | errorCode', errorCode);
    console.log('auth-context.js:31 | errorMessage', errorMessage);
  }
};

export const getCart: getCartFun = async (full_cart = false) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  let promotion: PromotionItem | false = false;
  if (!user.id) return { cart_db: false, promotion: false, cart_products: [] };
  let cart_products: CartProductItem[] = [];

  const collection = db.collection('cart').doc(user.id);
  const doc = await collection.get();

  const cart_db: CartItem | false = doc.exists
    ? (doc.data() as CartItem)
    : false;
  if (cart_db && cart_db.promotion) {
    promotion = cart_db.promotion;
    promotion.value = +cart_db.promotion.value;
    promotion.uses = +cart_db.promotion.uses;
  }

  let product_discounts = 0;

  const location = JSON.parse(localStorage.getItem('location') || '{}');
  const url = `scriptsCartChangeBranch`;
  const params = {
    user_id: user.id,
    xiaz_id: location.id,
  };

  let responseProducts: any;
  await axios
    .post(url, { ...params })
    .then((response) => {
      responseProducts = response.data;
    })
    .catch((err) => console.log('err', err));

  let promotionApplied = false;
  for (let key in responseProducts) {
    const product = responseProducts[key];
    if (promotion && !promotionApplied) {
      const regluar_price = +product.price;
      let new_price;
      if (promotion.type == 'percentage' || promotion.type == 'amount') {
        new_price = calcDesc(product, promotion);
      } else {
        new_price = regluar_price;
      }
      if (new_price !== regluar_price) {
        promotionApplied = true;
        product.price = new_price;
        product.regluar_price = regluar_price;
        product_discounts +=
          regluar_price * product.cant - new_price * product.cant;
      }
    }

    cart_products.push(product);
  }
  if (cart_db && product_discounts > 0) {
    cart_db.discount = product_discounts;
  }
  if (cart_products) {
    localStorage.setItem('cart', JSON.stringify(cart_products));
    updateCartCants();
  }
  if (full_cart) {
    return { cart_db, promotion, cart_products };
  }
  return { promotion, cart_products };
};

export const updateCart = async (data: any) => {
  const user: UserType = JSON.parse(localStorage.getItem('user') || '{}');
  if (!data || !user) return false;
  try {
    const collection = db.collection('cart').doc(user.id);
    await collection.set(
      {
        ...data,
      },
      { merge: true }
    );
    return;
  } catch (error) {
    console.log('cartFunctions.js:117 | error', error);
    return;
  }
};

export function calcDesc(product: CartProductItem, promotion: PromotionItem) {
  const price = Number(product.price);
  const amount = product.cant;
  const { addType, type, value } = promotion;

  switch (addType) {
    case 'products':
      if (
        promotion.idparts?.length &&
        product.idpart &&
        promotion.idparts.includes(product.idpart)
      ) {
        return applyPromotion();
      }
      return price;

    case 'brand':
      if (
        promotion.brands?.length &&
        product.aliasd &&
        promotion.brands.includes(product.aliasd)
      ) {
        return applyPromotion();
      }
      return price;

    case 'cats':
      if (
        promotion.cats?.length &&
        product.grpsbi &&
        (promotion.cats.includes(product.grpsbi[0]) ||
          promotion.cats.includes(
            `${product.grpsbi[0]} > ${product.grpsbi[1]}`
          ))
      ) {
        return applyPromotion();
      }
      return price;

    default:
      return price;
  }

  function applyPromotion() {
    switch (type) {
      case 'amount': {
        if (price <= value) {
          return price - price / amount;
        } else {
          return price - value / amount;
        }
      }

      case 'percentage': {
        return price - (price * promotion.value) / amount;
      }

      default:
        return price;
    }
  }
}
