import React from 'react';
import { InCartBlock } from '../../common/products/productBlock';
const CartItems = ({ products, handleDelete, changeFun }) => (
  <div className="cart_items">
    <Headers />
    <ProductList
      products={products}
      handleDelete={handleDelete}
      changeFun={changeFun}
    />
  </div>
);
const Headers = () => (
  <div className="headers xs_hidden">
    <div className="cart_row text-center border-0 ">
      <div className="products">
        <h3>Productos</h3>
      </div>
      <div className="price">
        <h3>Precio Unitario</h3>
      </div>
      <div className="price">
        <h3>Descuento</h3>
      </div>
      <div className="quanty">
        <h3>Cantidad</h3>
      </div>
      <div className="price">
        <h3>Precio</h3>
      </div>
      <div className="del"></div>
    </div>
  </div>
);

const ProductList = ({ products, handleDelete, changeFun }) => (
  <div className="products_list">
    {products.map((item) => {
      return (
        <InCartBlock
          key={item.id}
          product={item}
          handleDelete={handleDelete}
          changeFun={changeFun}
        />
      );
    })}
  </div>
);

export default CartItems;
