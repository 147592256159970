import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axios_firebase from '../middleware/axios';

import firebase, { db } from '../middleware/firebase';
import { updateCartCants } from '../middleware/common-functions';
import { getCart } from '../middleware/cartFunctions';
import { UserType } from '../models/user_model';
import { LocationItem } from '../models/branch_model';
import { CartProductItem } from '../models/cart_model';

type AuthContextProviderProps = {
  children: React.ReactChildren;
};

type Fingerprint = {
  OrgID: string;
  WebSessionID: string;
};

type AuthContextProps = {
  isAuth: boolean;
  location: false | LocationItem;
  fingerprint: false | Fingerprint;
  data: false | UserType;
  errors: any;
  loginFun: any;
  signUpFun: any;
  logoutFun: any;
  forgotFun: any;
  annonymousUser: any;
};

export const AuthContext = React.createContext<Partial<AuthContextProps>>({
  isAuth: false,
  location: false,
  fingerprint: false,
  data: {},
  errors: false,
  loginFun: () => {},
  signUpFun: () => {},
  logoutFun: () => {},
  forgotFun: () => {},
  annonymousUser: async () => {},
});

const AuthContextProvider: React.FC<AuthContextProviderProps> = ({
  children,
}) => {
  const [logged, setLogged] = useState(false);
  const [user_data, setUser_data] = useState<UserType | false>(false);
  const [location, setLocation] = useState<LocationItem | false>(false);
  const [fingerprint, setFingerprint] = useState<false | Fingerprint>(false);
  const [login_error, setLogin_error] = useState(null);
  let print = JSON.parse(localStorage.getItem('print') || '{}');

  useEffect(() => {
    const user: UserType = JSON.parse(localStorage.getItem('user') || '{}');
    let location = localStorage.getItem('location') || false;
    if (user.id) {
      setLogged(!user.isAnonymous);
      if (!user.isAnonymous) {
        setUser_data(user);
      }
    }
    ///get user location
    if (!location) {
      const location_data = getWarehouse('nacional');
      getUserIP();
      localStorage.setItem('location', JSON.stringify(location_data));
      setLocation(location_data);
    }
    if (user) {
      loadCart(user);
    }
  }, []);

  const createAnnonymousUser = async () => {
    ///create annonymus user
    try {
      const data = await firebase.auth().signInAnonymously();
      const user = {
        isAnonymous: true,
        id: data?.user?.uid,
      };
      localStorage.setItem('user', JSON.stringify(user));
      return user;
    } catch (error: any) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log('auth-context.js:30 | errorCode', errorCode);
      console.log('auth-context.js:31 | errorMessage', errorMessage);
      return null;
    }
  };

  const loadCart = (user: UserType) => {
    let collection;
    if (!user.id) return false;
    if (user.isAnonymous) {
      collection = db.collection('cart').doc(user.id);
    } else {
      const user_id = user.uid || user.id;
      collection = db.collection('cart').doc(user_id);
    }
    collection
      .collection('products')
      .get()
      .then((querySnapshot) => {
        const cart_products: CartProductItem[] = [];
        querySnapshot.forEach((doc) => {
          cart_products.push(doc.data() as CartProductItem);
        });
        localStorage.setItem('cart', JSON.stringify(cart_products));
      });
  };

  const loginHandler = async (
    email: string,
    password: string,
    type: string = 'email',
    refresh: boolean = false
  ) => {
    const user_email = email;
    if (typeof window.vCustomer != 'undefined') {
      window.vCustomer.startEvent(window.vCustomer.EVENT_TAGS.LOGIN_ATTEMPT);
    }
    if (type === 'email') {
      try {
        const { user: data_user } = await firebase
          .auth()
          .signInWithEmailAndPassword(user_email, password);
        if (!data_user) return;
        const { uid, email, displayName } = data_user;
        const user = { id: uid, email, displayName, phone: undefined };
        localStorage.setItem('user', JSON.stringify(user));
        setUser_data(user);
        ///clone cart to carts user db
        const cart: CartProductItem[] = JSON.parse(
          localStorage.getItem('cart') || '[]'
        );
        if (cart) {
          const collection = db
            .collection('cart')
            .doc(user.id)
            .collection('products');
          // Get a new write batch
          const batch = db.batch();
          cart.forEach((product) => {
            const product_collection = collection.doc(product.id);
            batch.set(product_collection, { ...product });
          });
          // Commit the batch
          await batch.commit();
        }
        ///get phone from db
        const user_collection = db.collection('users').doc(user.id);
        if (user_collection) {
          user_collection.get().then((doc) => {
            if (doc.exists) {
              const phone = doc.data()?.phone;
              if (phone) {
                user.phone = phone;
                localStorage.setItem('user', JSON.stringify(user));
              }
            }
          });
        }
        if (refresh) {
          window.location.reload();
        } else {
          ///get user cart
          await getCart();
          setLogged(true);
        }
      } catch (error: any) {
        setLogin_error(error);
        return;
      }
    }
  };
  const signUpHandler = async (
    email: string,
    phone: string,
    password: string,
    display_name: string,
    birthdate: string,
    type: 'email' | 'uid' = 'email'
  ) => {
    let user: UserType | null = {};
    if (!phone) return;
    if (!user_data || !user_data.id) {
      user = await createAnnonymousUser();
    }

    if (!user) return;
    if (type === 'email') {
      try {
        const credential = firebase.auth.EmailAuthProvider.credential(
          email,
          password
        );
        const usercred = firebase.auth();
        if (!usercred || !usercred.currentUser) return;
        const temp_user = await usercred.currentUser.linkWithCredential(
          credential
        );
        if (!temp_user || !temp_user.user) return;
        await temp_user.user.updateProfile({ displayName: display_name });
        user.id = temp_user.user.uid;
        const local_user = { id: user.uid, email, displayName: display_name };
        localStorage.setItem('user', JSON.stringify(local_user));
        setUser_data(local_user);

        const collection = db.collection('users').doc(user.id);
        const new_user_data = {
          id: user.id,
          birthdate,
          phone,
        };
        await collection.set(new_user_data, { merge: true });
        setLogged(true);
      } catch (error) {
        console.log('auth-context.js:148 | error', error);
      }
    }
  };

  const logoutHandler = () => {
    setLogged(false);
    localStorage.removeItem('user');
    localStorage.removeItem('cart');
    localStorage.removeItem('print');
    localStorage.removeItem('modalView');
    updateCartCants();
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.location.reload();
      });
  };

  const forgotHandler = (user_email: string) => {
    if (typeof window.vCustomer != 'undefined') {
      window.vCustomer.startEvent(window.vCustomer.EVENT_TAGS.FORGOT_PASSWORD);
    }
    return new Promise(async (res, rej) => {
      try {
        await firebase.auth().sendPasswordResetEmail(user_email);
        if (typeof window.vCustomer != 'undefined') {
          window.vCustomer.finishEvent(
            window.vCustomer.EVENT_TAGS.FORGOT_PASSWORD
          );
        }
        return res(true);
      } catch (error: any) {
        // An error happened.
        console.log('auth-context.js:185 | error', error);
        return rej({ error: error.code });
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        loginFun: loginHandler,
        signUpFun: signUpHandler,
        logoutFun: logoutHandler,
        forgotFun: forgotHandler,
        annonymousUser: createAnnonymousUser,
        errors: login_error,
        isAuth: logged,
        data: user_data,
        location: location,
        fingerprint: fingerprint,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function getWarehouse(city_name: string): LocationItem {
  city_name = city_name
    .replace('City', '')
    .replace(' ', '')
    .toLocaleLowerCase();

  switch (city_name) {
    case 'chihuahua':
      return {
        city: 'Zona Chihuahua',
        id: '1',
      };
    case 'hermosillo':
      return {
        city: 'Zona Hermosillo',
        id: '2',
      };
    case 'juarez':
      return {
        city: 'Cd. Juárez',
        id: '3',
      };
    case 'delicias':
      return {
        city: 'Delicias',
        id: '5',
      };
    case 'cuauhtemoc':
      return {
        city: 'Chuahutémoc',
        id: '6',
      };
    case 'parral':
      return {
        city: 'Parral',
        id: '7',
      };

    default:
      return {
        city: 'Nacional',
        id: '1',
      };
  }
}

const getUserIP = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
    const { ip } = response.data;
    localStorage.setItem('location_ip', ip);
    return ip;
  } catch (error) {
    console.error('Failed to get user IP:', error);
    return false;
  }
};

export default AuthContextProvider;
