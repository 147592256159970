import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { db } from '../../../middleware/firebase';
import { PopupType } from '../../../models/banners_model';

interface PopupProps {
  filter?: string;
}

interface HeroItemProps {
  item: PopupType;
  filter: string;
}

interface ContainerProps {
  item: PopupType;
}

const Popup: React.FC<PopupProps> = ({ filter = 'img' }) => {
  const page =
    process.env.REACT_APP_IS_PROD === 'false'
      ? 'mathasa.mx'
      : window.location.host;
  const [popup, setPopup] = useState<PopupType | null>(null);
  const viewed = JSON.parse(localStorage.getItem("modalView") || "[]");
  const [modal, setModal] = useState(false);
  console.log(popup);

  useEffect(() => {
    fetchPopups();
  }, []);

  const fetchPopups = async () => {
    const popupsRef = db.collection('popups');
    const location = JSON.parse(
      localStorage.getItem('location') || '{"city":"Nacional","id":"0"}'
    );
    const start = new Date();
    const snapshot = await popupsRef
      .where('valid', '>=', start)
      .where('websites', 'array-contains', page)
      .where('from', '<=', start)
      .get();
    if (snapshot.empty) {
      return;
    }

    for (const doc of snapshot.docs) {
      const popup_data = doc.data() as PopupType;
      popup_data.id = doc.id;
      if (typeof popup_data.from === 'undefined') {
        continue;
      }
      if (popup_data.zones) {
        const finded = popup_data.zones.findIndex(
          (zone) => zone === location.id
        );
        if (finded < 0) {
          continue;
        }
      }
      const hasViewed = viewed.includes(popup_data.id);
      if (!hasViewed) {
        setModal(true);
        setPopup(popup_data);
      }
    }
  };

  if (!popup) {
    return null;
  }
  const removeModal = () => {
    ////save view in localhost to avoid showing the popup again
    const viewed = JSON.parse(localStorage.getItem("modalView") || "[]");
    viewed.push(popup!.id);
    localStorage.setItem("modalView", JSON.stringify(viewed));
    setModal(false);
  };
  return (
    <div className="popup">
      return (
      <Modal
        isOpen={modal}
        toggle={removeModal}
        className="bg-white"
        size="lg"
        id="student_modal"
      >
        <ModalHeader toggle={removeModal}>{popup.title}</ModalHeader>
        <img
          src={popup.img}
          className="img-fluid  "
          alt="modal"
        />
        <ModalBody>
          <p>{popup.description}</p>
        </ModalBody>
        <ModalFooter style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            type="button"
            className="btn btn-outline-secondary"
            data-dismiss="modal"
            onClick={removeModal}
          >
            {"Close"}
          </button>
          <a
            className="btn px-4 btn-danger text-white"
            data-dismiss="modal"
            href={popup.link}
            target="_blank"
            rel="noreferrer"
            onClick={removeModal}
          >
            {popup.link_text || "ver Más"}
          </a>
        </ModalFooter>
      </Modal>
      );
    </div>
  );
};

const HeroItem: React.FC<HeroItemProps> = ({ item, filter }) => (
  <div className="hero_container">
    <img src={item.img} alt={item.link} />
    {item.link && <Link className="full_link" to={item.link} />}
    {item.title && (
      <Container
        item={item}
      />
    )}
  </div>
);

const Container: React.FC<ContainerProps> = ({
  item,
}) => (
  <div className="container">
    <div className="finder_select">
      <h1>{item.title}</h1>
      {item.description && <p>{item.description}</p>}
      {item.link && (
        <a
          href={item.link}
          className={`btn text-capitalize btn-block btn-light `}
        >
          {item.link_text}
        </a>
      )}
    </div>
  </div>
);

export default Popup;
